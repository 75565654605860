import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CenterFlex } from "styled/common/Utils";
import styled, { css } from "styled-components";

export type SubPageVariant = "aloki";

export const ImageFullSizeContainer = styled.section<{ subPage?: SubPageVariant }>`
  width: 120%;
  margin-left: -10%;
  ${CenterFlex}
  ${CalculateFromHeight("height", { mobile: 80, tablet: 60, desktop: 135 })}

  ${({ subPage }) =>
    subPage === "aloki" &&
    css`
      ${CalculateFromHeight("margin-top", { mobile: 10 })};
    `}
`;

export const ImageFullSizeWrapper = styled.div`
  ${CalculateFromWidth("width", { mobile: 120, tablet: 120, desktop: 120 })}
  max-width: 100vw;
`;
