import React from "react";

import {
  ImageFullSizeContainer,
  ImageFullSizeWrapper,
  type SubPageVariant
} from "components/images/ImageFullSize/ImageFullSize.styled";
import { useImageDatabaseContext } from "contexts/ImageContext";
import { GatsbyImage } from "gatsby-plugin-image";

interface ImageFullSizeProps {
  imageName: string;
  subPage?: SubPageVariant;
}

const ImageFullSize = ({ imageName, subPage }: ImageFullSizeProps) => {
  const [imageDatabase] = useImageDatabaseContext();

  return (
    <ImageFullSizeContainer subPage={subPage}>
      <ImageFullSizeWrapper>
        <GatsbyImage
          image={imageDatabase[imageName].childImageSharp.gatsbyImageData}
          draggable={false}
          alt="Project full size image"
        />
      </ImageFullSizeWrapper>
    </ImageFullSizeContainer>
  );
};

export default ImageFullSize;
