import React from "react";

import type { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import ImageFullSize from "components/images/ImageFullSize/ImageFullSize";
import SquareImage from "components/images/ImageSquare/ImageSquare";
import Layout from "components/layout/Layout/Layout";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import ProjectInformation, {
  type ProjectInformationProps
} from "components/pages/project/ProjectInformation/ProjectInformation";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import ProjectData from "data/projects-page-data.json";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const Aloki = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("Aloki")} />
      <Layout location={location}>
        <MainCloud color="DarkPurpleBlue" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...(ProjectData.Aloki.pageTitleData as PageTitleProps)} />
        <ProjectInformation {...(ProjectData.Aloki as ProjectInformationProps)} />
        <SquareImage
          title="Aloki_Header_Chance"
          imageName="Aloki1Rect"
          description="Aloki_Text_Chance"
          left
        />
        <SquareImage
          title="Aloki_Header_Game"
          imageName="Aloki2Rect"
          description="Aloki_Text_Game"
        />
        <ImageFullSize imageName="Aloki1" />
        <SquareImage
          title="Aloki_Header_Guide"
          imageName="Aloki3Rect"
          description="Aloki_Text_Guide"
          left
        />
        <SquareImage
          title="Aloki_Header_Virtual"
          imageName="Aloki4Rect"
          description="Aloki_Text_Virtual"
        />
        <SquareImage
          title="Aloki_Header_Truly"
          imageName="Aloki5Rect"
          description="Aloki_Text_Truly"
          left
        />
        <ImageFullSize imageName="Aloki2" subPage="aloki" />
        <HomeProjects headerData={headerData} />
      </Layout>
    </>
  );
};

export default Aloki;

const headerData: HeaderGroupProps = {
  instantPlayback: false,
  animationPlaybackOffset: -0.75,
  headers: [
    {
      text: "Projects_Header_Other",
      variant: "HomeProjectsOur",
      id: "home-projects-our",
      animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
      horizontalScrollSpeed: -0.75
    },
    {
      text: "Home_Header_Projects",
      variant: "HomeProjectsWork",
      id: "home-projects-work",
      animation: { x: "30%", opacity: 0, duration: 0.75, delay: 0.125, ease: Circ.easeOut },
      horizontalScrollSpeed: 0.75
    }
  ]
};
